import React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../routes";
import styled from "styled-components";
import {
  getFlexCenter,
  getHorizontalGap,
  getVerticalGap,
} from "../../common/styles";
import Button from "../../common/components/Buttons/Button";
import ButtonOutlined from "../../common/components/Buttons/ButtonOutlined";
import {
  getHeading4BoldTypography,
  getHeading6BoldTypography,
} from "../../common/Typography";

const Root = styled.div`
  position: relative;
  padding-top: 164px;
  ${getFlexCenter};
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 64px;
  }
`;

const BackgroundGradient = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(50% 50% at 50% 50%, #083e7b 0%, #131112 100%);
  transform: translate(25%, 50%);
  z-index: -1;
`;

const Header = styled.p`
  color: ${(props) => props.theme.primaryColor};
  ${getHeading4BoldTypography};
  font-family: Poppins, sans-serif;
  font-size: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${getHeading6BoldTypography};
  }
`;

const NotFound = styled.p`
  color: ${(props) => props.theme.lightBorderColor};
  font-size: 210px;
  line-height: 1;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.2em;
  text-indent: 0.2em;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 44px;
    font-size: 110px;
  }
`;

const ActionsWrapper = styled.div`
  margin-top: 44px;
  display: flex;
  ${getHorizontalGap("45px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: 0 20px;
    ${getHorizontalGap("0")};
    ${getVerticalGap("8px")};
    flex-direction: column;
  }
`;

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <BackgroundGradient />
      <Header>{t("404-message")}</Header>
      <NotFound>404</NotFound>
      <ActionsWrapper>
        <ButtonOutlined
          href={routes.marketplace.root}
          text={t("go-to-marketplace")}
        />
        <Button href={routes.home} text={t("go-to-home-page")} />
      </ActionsWrapper>
    </Root>
  );
};

export default Page404;
